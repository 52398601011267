#not-found {
    background-color: #E8F8FF;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

}

#not-found__text {
    font-size: 20px;
    font-weight: bold;
}