$color_1: #6366F1;
$color_2: white;
$color_3: #fff;
$background-color_1: #fff;
$background-color_2: #f5f5f5;
$background-color_3: #6366F1;
$background-color_4: #f00;

.k-main-header {
    height: 64px;
    z-index: 2;
    display: flex;
    padding: 32px;
    align-items: center;
    justify-content: space-between;
    background-color: $background-color_1;
    font-family: "Inter", sans-serif !important;
    width: 100%;
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;

    .k-header-logo {
        margin-bottom: 2px;
        min-width: 72px;
        letter-spacing: 0;
        line-height: 64px;
        height: 64px;
        white-space: nowrap;
        cursor: pointer;

        .k-logo {
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 32px;
            color: $color_1;
        }
    }

    .k-header-content {
        display: flex;
        align-items: center;
        height: 64px;
        justify-content: space-between;
        width: 100%;

        .k-header-content-left {
            display: flex;
            align-items: center;
            gap: 40px;
        }

        .header-logo {
            cursor: pointer;
            //center logo

            color: $color_1;
            // font-size: 25px;
            // font-weight: 700;

            img {
                width: 150px;
                object-fit: cover;
            }

            font-size: 25px;
            font-weight: 700;


        }

        .header-content-menu {
            display: flex;
            align-items: center;
            gap: 30px;

            .header-content-menu-item {
                cursor: pointer;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0;
                white-space: nowrap;
                transition: all 0.3s ease-out;
            }

            .header-content-menu-item:hover {
                color: $color_1;
            }

            .header-content-menu-item.active {
                color: $color_1;
            }
        }

        .header-content-input {
            position: relative;
            height: 64px;
            display: flex;
            padding: 8.5px 10px;
            align-items: center;

            .search-input {
                min-width: none;
                width: 800px;
                letter-spacing: 0;
                line-height: 20px;
                white-space: nowrap;
                background-color: $background-color_2;
                border-radius: 10px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;

                &:hover {
                    border: 1px solid $color_1 !important;
                }

                &:focus {
                    border: 1px solid $color_1 !important;
                }
            }

            .icon-search {
                position: absolute;
                right: 15px;
                padding: 7px;
                pointer-events: none;
            }
        }

        .header-content-info {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .header-farmInfo {
                display: flex;
                align-items: center;
                gap: 10px;
                // cursor: pointer;
                padding: 10px;
                background-color: $background-color_2;
                border-radius: 10px;

                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                max-width: 200px;
                //if text is too long then show ellipsis
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                //hover not working
                cursor: default ;


                box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
            }

            .header-avatar-info {
                display: flex;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                padding: 10px;
            }

            .header-avatar-info:hover {
                color: $color_1;
                background-color: $background-color_2;
                border-radius: 10px;
            }
        }

        .header-button {
            height: 32px;
            display: flex;
            padding: 4.5px 19px;
            align-items: flex-start;
            gap: 8px;
            border-radius: 10px;
            border: 1.5px solid $color_1;
            font-family: "Inter", sans-serif;

            .header-button-icon {
                width: 20px;
                height: 20px;
                align-self: center;
            }

            .icon-add-camera {
                width: 20px;
                height: 20px;
                align-self: center;
                margin: 0 -5px;
            }

            .header-button-text {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                // line-height: 20px;
                text-align: center;
                color: $color_1;
                font-family: "Inter", sans-serif !important;
            }

            .header-button-text-home {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                // line-height: 20px;
                text-align: center;
                color: $color_1;
                font-family: "Inter", sans-serif !important;
            }


        }

        .header-button:hover {
            background-color: $background-color_3 !important;
            border: 1.5px solid $background-color_3 !important;

            .header-button-text {
                color: $color_2;
            }

            .header-button-text-home {
                color: $color_2;
            }
        }

        .header-notification {
            padding: 10px;
            position: relative;
            margin-left: 21px;
            cursor: pointer;

            .header-icon-notification {
                position: absolute;
                width: 23px;
                height: 27px;
                top: 3px;
                left: 0;
            }

            .header-number-notification {
                position: absolute;
                height: 14px;
                top: 0;
                left: 11px;
                display: flex;
                padding: 8 3.5px;
                align-items: flex-start;
                min-width: 14px;
                background-color: $background-color_4;
                border-radius: 30px;

                .number-noti {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 10px;
                    margin-left: 3.5px;
                    color: $color_3;
                    letter-spacing: 0;
                    white-space: nowrap;
                }
            }
        }

        .header-avatar {
            margin-left: 18px;
            background-image: url('../../images/user_icon.png');
            background-size: cover;
            background-position: 50% 50%;
            cursor: pointer;
        }
    }
}

.k-main-header.not-user {
    height: 64px;
    z-index: 2;
    display: flex;
    padding: 32px;
    align-items: center;
    justify-content: center;
    background-color: $background-color_1;
    font-family: "Inter", sans-serif !important;

    .k-header-logo {
        margin-bottom: 2px;
        min-width: 72px;
        letter-spacing: 0;
        line-height: 64px;
        height: 64px;
        white-space: nowrap;
        cursor: pointer;

        .k-logo {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: $color_1;
        }
    }

    .k-header-content {
        display: flex;
        align-items: center;
        height: 64px;
        justify-content: space-between;
        width: 100%;
        max-width: 1000px;

        .k-header-content-left {
            display: flex;
            align-items: center;
            gap: 40px;
        }

        .header-logo {
            cursor: pointer;

            color: $color_1;
            // font-size: 25px;
            // font-weight: 700;

            img {
                width: 150px;
                object-fit: cover;
            }

        }

        .header-content-menu {
            display: flex;
            align-items: center;
            gap: 20px;

            .header-content-menu-item {
                cursor: pointer;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0;
                white-space: nowrap;
                transition: all 0.3s ease-out;
            }

            .header-content-menu-item:hover {
                color: $color_1;
            }

            .header-content-menu-item.active {
                color: $color_1;
            }
        }

        .header-content-input {
            position: relative;
            height: 64px;
            display: flex;
            padding: 8.5px 10px;
            align-items: center;

            .search-input {
                min-width: none;
                width: 800px;
                letter-spacing: 0;
                line-height: 20px;
                white-space: nowrap;
                background-color: $background-color_2;
                border-radius: 10px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;

                &:hover {
                    border: 1px solid $color_1 !important;
                }

                &:focus {
                    border: 1px solid $color_1 !important;
                }
            }

            .icon-search {
                position: absolute;
                right: 15px;
                padding: 7px;
                pointer-events: none;
            }
        }

        .header-content-info {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .header-button {
            height: 32px;
            display: flex;
            padding: 4.5px 19px;
            align-items: flex-start;
            gap: 8px;
            border-radius: 10px;
            border: 1.5px solid $color_1;
            font-family: "Inter", sans-serif;

            .header-button-icon {
                width: 20px;
                height: 20px;
                align-self: center;
            }

            .icon-add-camera {
                width: 20px;
                height: 20px;
                align-self: center;
                margin: 0 -5px;
            }

            .header-button-text {
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 20px;
                text-align: center;
                color: $color_1;
                font-family: "Inter", sans-serif !important;
            }

            .header-button-text {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                // line-height: 20px;
                text-align: center;
                color: $color_1;
                font-family: "Inter", sans-serif !important;
            }
        }

        .header-button:hover {
            background-color: $background-color_3 !important;
            border: 1.5px solid $background-color_3 !important;

            .header-button-text {
                color: $color_2;
            }
        }

        .header-notification {
            width: 25px;
            height: 30px;
            position: relative;
            margin-left: 21px;
            margin-bottom: 3.43px;
            cursor: pointer;

            .header-icon-notification {
                position: absolute;
                width: 23px;
                height: 27px;
                top: 3px;
                left: 0;
            }

            .header-number-notification {
                position: absolute;
                height: 14px;
                top: 0;
                left: 11px;
                display: flex;
                padding: 8 3.5px;
                align-items: flex-start;
                min-width: 14px;
                background-color: $background-color_4;
                border-radius: 30px;

                .number-noti {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 10px;
                    margin-left: 3.5px;
                    color: $color_3;
                    letter-spacing: 0;
                    white-space: nowrap;
                }
            }
        }

        .header-avatar {
            margin-left: 18px;
            background-image: url('../../images/user_icon.png');
            background-size: cover;
            background-position: 50% 50%;
            cursor: pointer;
        }
    }
}





.ant-layout.ant-layout-has-sider.site-layout {
    display: flex;
    flex-direction: column;
}

$color_1: #FFFFFF;
$color_2: white;
$color_3: #6366F1;
$font-family_1: "Inter", sans-serif;
$background-color_1: #6366F1;

.body {
    font-family: $font-family_1;
    font-style: normal;
}

.add-camera-modal {
    .ant-modal-content {
        padding: 0;
    }

    .ant-modal-header {
        padding: 16px 32px;
        background: #6366F1;
    }

    .ant-modal-title {
        text-align: left;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $color_1;
    }

    .ant-modal-close {
        display: block;
        color: $color_2;

        &:hover {
            color: $color_2;
        }
    }

    .ant-modal-close-x {
        font-size: 20px;
    }

    .ant-modal-body {
        padding: 24px;
    }

    .ant-modal-footer {
        text-align: center;
        padding-bottom: 32px;
        margin-top: -10px;
    }

    .ant-btn.ant-btn-default {
        height: 32px;
        padding: 4.5px 19px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 10px;
        border: 1.5px solid #6366F1;
        color: $color_3;
    }

    .btn-cancel {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: $color_3;
    }

    .btn-submit {
        background-color: $background-color_1;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: $color_1;

        &:hover {
            background-color: $background-color_1;
        }
    }

    .ant-picker {
        width: 100%;
    }

    .ant-modal-footer {
        display: none;
    }

    .ant-btn {
        border-radius: 8px !important;
    }


    .ant-col.ant-form-item-label {
        height: 30px;
    }

    .ant-form-item-label>label {
        display: inline;
    }

    .custom-label {
        display: flex;
        gap: 4px;
        align-items: center;
    }

    .required-asterisk {
        color: #ff4d4f;
    }


}

.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}