.body {
  font-family: "Inter", sans-serif;
  font-style: normal;
}
.add-camera-modal .ant-modal-content {
  padding: 0;
}

.add-camera-modal .ant-modal-header {
  padding: 16px 32px;
  background: #6366f1;
}

.add-camera-modal .ant-modal-title {
  text-align: left;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 24px;
  color: #ffffff;
}

.add-camera-modal .ant-modal-close {
  display: block;
  color: white;
}

.add-camera-modal .ant-modal-close:hover {
  color: white;
}

.add-camera-modal .ant-modal-close-x {
  font-size: 20px;
}

.add-camera-modal .ant-modal-body {
  padding: 24px;
}

.add-camera-modal .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 32px;
  margin-top: -10px;
}

.add-camera-modal .ant-btn.ant-btn-default {
  height: 32px;
  padding: 4.5px 19px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 1.5px solid #6366f1;
  color: #6366f1;
}

.add-camera-modal .btn-cancel {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 24px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6366f1;
}

.add-camera-modal .btn-submit {
  background-color: #6366f1;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.add-camera-modal .btn-submit:hover {
  background-color: #6366f1;
}

.edit-warehouse-form .ant-form-item-label > label {
  width: fit-content;
  justify-content: end;
}

/* .edit-warehouse-form
  :where(.css-dev-only-do-not-override-p7e5j5).ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
} */

.edit-warehouse-form strong {
  color: red;
}
