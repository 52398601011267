$color_1: #6366F1;
$color_2: white;
$color_3: #fff;
$font-family_1: "Inter", sans-serif;
$background-color_1: #fff;
$background-color_2: #f5f5f5;
$background-color_3: #6366F1;
$background-color_4: #f00;

.home-page {
    display: flex;
    padding: 32px;
    background-color: $background-color_1;
    font-family: "Inter", sans-serif !important;
    height: 78vh;
    justify-content: center;

    .home-page-content {
        max-width: 1000px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .biggest-text-content {
            font-family: "Inter", sans-serif;
            font-size: 45px;
            font-weight: 700;
            line-height: 1.2;
            width: 1000px;
        }

        .small-text-content {
            font-family: "Inter", sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 1.5;
            width: 540px;
            // set text align to justify
            text-align: justify;

        }
    }
}