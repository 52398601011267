// .container {
//   display: grid;
//   grid-gap: 20px; // Adjust the gap between items as needed

//   // Default to 1 camera per row for small screens
//   grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

//   .ant-card {
//     width: 300px;
//     min-height: 400px;
//   }

//   @media (max-width: 750px) {
//     grid-template-columns: repeat(1, 1fr);

//     .ant-card {
//       width: 100%;
//     }
//   }

//   // Medium screens: 2 cameras per row
//   @media (min-width: 750px) {
//     grid-template-columns: repeat(2, 1fr);
//   }

//   // Large screens: 3 cameras per row
//   @media (min-width: 1060px) {
//     grid-template-columns: repeat(3, 1fr);
//   }

//   // Extra large screens: 4 cameras per row
//   @media (min-width: 1360px) {
//     grid-template-columns: repeat(4, 1fr);
//   }


// }

.camera-manager-card {
  box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014
}

.camera-item {
  position: relative;

}

.button-delete {
  position: absolute;
  bottom: 20px;
  right: 20px;

  padding: 5px 10px;
  box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014;
  border-radius: 5px;  
  cursor: pointer;
  transition: all 0.3s ease;


  &:hover {
    background-color: black;
    color: white;
  }
}

.button-edit {
  position: absolute;
  bottom: 20px;
  right: 70px;

  padding: 5px 10px;
  box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014;
  border-radius: 5px;  
  cursor: pointer;
  transition: all 0.3s ease;


  &:hover {
    background-color: black;
    color: white;
  }
}

.ant-card-body {
  div {
    font-size: 16px;
  }
}