.camera-detail-main {
  display: flex;
  width: 100% !important;
  height: 100% !important;
  font-family: "Inter", sans-serif;
  font-style: normal;
}

.camera-detail-main .main-content img {
  width: 100%;
  height: 100%;
}

.camera-detail-main .camera-detail-left {
  width: 90%;
  padding: 30px 50px;
  padding-right: 0;
}

.camera-detail-main .camera-detail-left .ant-image {
  width: 100%;
}

.camera-detail-main .camera-detail-left .camera-detail-image {
  width: 100%;
  height: 50%;
}

.camera-detail-main .camera-detail-left .camera-detail-title {
  display: flex;
  justify-content: space-between;
}

.camera-detail-main .camera-detail-left .camera-detail-title .title-name {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.camera-detail-main .camera-detail-left .camera-detail-title .title-icon img {
  margin-left: 10px;
  height: 14px;
  width: 14px;
}

.camera-detail-main .camera-detail-left .camera-detail-date {
  display: flex;
  color: #858585;
  font-size: 13px;
}

.camera-detail-note {
  margin-top: 10px;
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #858585;
}

.camera-detail-link {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
  /* justify-content: space-between; */
  /* align-items: baseline; */
}

.camera-detail-link .link-url {
  font-size: 11px;
  line-height: 24px;
  color: #858585;
}

.camera-detail-link .camera-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
}

.camera-detail-button {
  border: 1.5px solid #6366f1 !important;
  border-radius: 30px;
  color: #6366f1;
  margin-left: 10px;
}

.camera-detail-button:hover {
  border: 1.5px solid #6366f1 !important;
  background-color: #6366f1;
  color: white !important;
  transition: all 0.3s;
}

.camera-detail-right {
  width: 40%;
  padding: 20px 0;
}

.camera-detail-right .camera-detail-list .ant-list-items {
  display: flex;
  flex-direction: column;
  border: none !important;
}

.camera-detail-right .ant-list-split .ant-list-item {
  border: none !important;
  padding-right: 20px;
  margin-bottom: 20px;
}

.camera-detail-right .camera-detail-list .ant-card.camera-detail-card {
  display: flex;
  background-color: #f5f5f5;
}

.camera-detail-right .camera-detail-list .ant-card-cover {
  width: 200px;
  height: 80px;
}

.camera-detail-right .camera-detail-list .ant-card-cover .ant-image {
  width: 200px;
  height: 80px;
}

.camera-detail-right .camera-detail-list .ant-card-body {
  padding: 0px 20px;
  padding-right: 0;
}

.camera-detail-right .ant-card-meta-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #4e4e4e;
}

.camera-detail-right .ant-card-meta-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #858585;
}
