.policies-terms-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;

  h1 {
    font-size: 2.5rem;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 2rem;
    border-bottom: 2px solid #3498db;
    padding-bottom: 1rem;
  }

  section {
    margin-bottom: 2rem;

    h2 {
      font-size: 1.8rem;
      color: #2980b9;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1rem;
      margin-bottom: 1rem;
      text-align: justify;
    }
  }

  // Responsive design
  @media (max-width: 768px) {
    padding: 1rem;

    h1 {
      font-size: 2rem;
    }

    section {
      h2 {
        font-size: 1.5rem;
      }

      p {
        font-size: 0.9rem;
      }
    }
  }
}
