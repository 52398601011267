.userList-container {
    font-family: "Inter", sans-serif;
    font-style: normal;
    padding: 1em 2em;
    background-color: white;
    border-radius: 15px;
    margin: 1em 2em;
    // height: fit-content;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.0196078431), 0 0 2px rgba(0, 0, 0, 0.0509803922), 0 1px 4px rgba(0, 0, 0, 0.0784313725);


    .button-userList {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: nowrap;

        .userList-h2 {
            font-size: 28px;
            font-weight: bolder;
            font-family: "Inter", sans-serif
        }




        .search-userList {
            width: 20em;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .ant-input-group-wrapper {
            padding-right: 0.5em;

            .ant-input-affix-wrapper:hover,
            .ant-input-group-wrapper:focus {
                color: #6366F1 !important;
                border-color: #6366F1 !important;
                background: #FFFFFF !important;
                outline: none !important;
                box-shadow: 0 0 0 2px rgba(255, 90, 128, 0.2) !important;
            }
        }

        .ant-select {
            width: 10em !important;

            .ant-select-selector {
                color: #ffffff;
                background-color: #6366F1;
                border-radius: 10px;
            }

            .ant-select-selector:hover,
            .ant-select-selector:focus {
                color: black;
                background-color: #FFFFFF;
                border-radius: 10px;
            }
        }
    }

    .table-userList {
        margin-top: 20px;
        // min-height: 800px;
        font-family: "Inter", sans-serif;

        .ant-table-wrapper {
            border-radius: 6px;

            .ant-table-thead {
                tr {
                    th {
                        font-weight: 700;
                        text-align: center;
                        font-family: "Inter", sans-serif;
                    }

                    .ant-table-cell:nth-child(1) {
                        width: 5%;
                    }

                    .ant-table-cell:nth-child(2) {
                        width: 10%;
                    }

                    .ant-table-cell:nth-child(3) {
                        width: 15%;
                    }

                    .ant-table-cell:nth-child(4) {
                        width: 15%;
                    }

                    .ant-table-cell:nth-child(5) {
                        width: 20%;
                    }

                    .ant-table-cell:nth-child(6) {
                        width: 15%;
                    }

                    .ant-table-cell:nth-child(7) {
                        width: 20%;
                    }
                }
            }

            .ant-table-tbody {
                .ant-table-cell {
                    text-align: center;
                    font-family: "Inter", sans-serif;
                }
            }
        }


        .odd-row {
            background-color: #ffffff;
        }

        th {
            background-color: #b0b1e8;
        }
    }
}

.text-link {
    cursor: pointer;
    color: #6366F1;
    margin-top: 5px;
}

.text-link:hover {
    color: #8284f0;
}

.ant-btn:hover,
.ant-btn:focus {
    color: #6366F1 !important;
    border-color: #6366F1 !important;
    background: #FFFFFF !important;
}

.ant-space.ant-space-horizontal.ant-space-align-center {
    gap: 1em !important;
}

.ant-pagination {
    display: flex;
    align-items: center;
    justify-content: center !important;

    .ant-pagination-item {
        border-radius: 10px;
    }

    .ant-pagination-item-link {
        border-radius: 10px !important;
    }

    .ant-pagination-item-active,
    .ant-pagination-item-active:focus-visible,
    .ant-pagination-item-active:hover,
    .ant-pagination-item:focus-visible,
    .ant-pagination-item:hover {
        border-color: #6366F1;

        a {
            color: #6366F1;
        }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        .ant-pagination-item-link:hover {
            border-color: #6366F1;
            color: #6366F1;
        }
    }

    .anticon svg {
        margin-bottom: 6px;
    }
}

.ant-spin {
    .ant-spin-dot-item {
        background-color: #6366F1;
    }

    .ant-spin-text {
        color: #6366F1;
    }
}

.ant-modal-root {
    .ant-modal-wrap {

        .ant-modal {
            .ant-modal-content {
                height: 100% !important;
                max-height: 100% !important;
            }

            .ant-modal-body {
                .action-btn {
                    display: flex;
                    justify-content: flex-end;
                    gap: 1em;

                    .cancel-btn {
                        color: rgba(0, 0, 0, 0.85) !important;
                        background-color: white !important;
                        border: 1px solid rgba(0, 0, 0, 0.85) !important;
                    }

                    .cancel-btn:hover {
                        color: #6366F1 !important;
                        background-color: white !important;
                        border: 1px solid #6366F1 !important;
                    }
                }
            }
        }

        .delete-modal {
            .ant-modal-body {
                display: flex;
                flex-direction: column;
                gap: 1em;


            }

        }
    }


}

.ant-slider-handle {
    border: solid 2px #b0b1e8 !important;
}

.ant-slider-track {
    background-color: #b0b1e8 !important;
}

.ant-input:hover {
    border-color: #6366F1 !important;
}

.ant-input:focus {
    border-color: #6366F1 !important;
    outline: none !important;
    box-shadow: 0 0 0 2px rgba(255, 90, 128, 0.2) !important;
}

.ant-btn-primary {
    background-color: #6366F1 !important;
    border-color: #6366F1 !important;
    color: #FFFFFF !important;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
    border-color: #6366F1 !important;
    outline: none !important;
    box-shadow: 0 0 0 2px rgba(255, 90, 128, 0.2) !important;
}

.ant-form-item-label>label {
    width: 12em;
    justify-content: end;
}

.button-excel {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
}

.no-data{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 20px;
    font-weight: 700;
    color: #6366F1;
}