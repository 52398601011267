.footer {
    // margin-top: 100px;
    width: 100%;
    background-color: #25267a;
    padding: 1em 2em;

    display: block;
    position: relative;
    // margin-top: 50vh;
    bottom: 0;

    #footer-content {
        display: flex;
        justify-content: space-between;

        #left-footer {
            width: 50%;

            #footer-logo {
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 32px;
                color: #fff;
            }


            #footer-link {
                display: grid;
                grid-gap: 12px;
                margin: 12px 0;
                font-size: 14px;
                line-height: 24px;

                a {
                    color: #fff !important;
                    font-size: 16px;
                }

                #row-link {
                    color: #d9dbe1;

                    ul {
                        display: flex;

                        li {
                            display: inline-block;
                            margin-right: 24px;
                        }
                    }
                }

                .nav-menu-footer {
                    justify-content: unset !important;

                    .underline-menu {
                        display: inline-block;
                        padding: 16px 0px;
                        position: relative;
                    }
                }
            }

            #rights {
                color: #D9DBE1;
                font-size: 16px;
            }
        }

        #right-footer {
            #right-footer-title {
                font-size: 18px;
                color: #D9DBE1;
            }

            .download-link {
                width: 157px;
                margin-top: 18px;

                img {
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.footer.not-user {
    display: flex;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;

    #footer-content {
        max-width: 1000px;
        width: 100%;

        #left-footer {
            width: 100%;

            #footer-link {
                #row-link {
                    .row-link {
                        display: flex;
                        gap: 10px;
                        flex-direction: column;
                        width: 100%;
                    }
                }
            }
        }

    }
}

@media (max-width: 1920px) {
    #footer {
        #footer-content {

            #left-footer {
                width: 50%;

                #footer-link {
                    display: grid;
                    grid-gap: 12px;
                    margin: 12px 0;
                    font-size: 16px;

                    a {
                        color: #fff !important;
                        font-size: 16px;
                    }

                    #row-link {
                        color: #d9dbe1;

                        ul {
                            display: flex;

                            li {
                                display: inline-block;
                                margin-right: 24px;
                            }
                        }
                    }

                    .nav-menu-footer {
                        justify-content: unset !important;

                        .underline-menu {
                            display: inline-block;
                            padding: 16px 0px;
                            position: relative;
                        }
                    }
                }

                #rights {
                    color: #D9DBE1;
                    font-size: 16px;
                }
            }

            #right-footer {
                #right-footer-title {}

                .download-link {

                    img {}
                }
            }
        }
    }
}

@media (max-width: 1720px) {
    #footer {
        #footer-content {

            #left-footer {
                width: 50%;

                #footer-link {
                    display: grid;
                    grid-gap: 12px;
                    margin: 12px 0;
                    font-size: 16px;

                    a {
                        color: #fff !important;
                        font-size: 16px;
                    }

                    #row-link {
                        color: #d9dbe1;

                        ul {
                            display: flex;

                            li {
                                display: inline-block;
                                margin-right: 24px;
                            }
                        }
                    }

                    .nav-menu-footer {
                        justify-content: unset !important;

                        .underline-menu {
                            display: inline-block;
                            padding: 16px 0px;
                            position: relative;
                        }
                    }
                }

                #rights {
                    color: #D9DBE1;
                    font-size: 16px;
                }
            }

            #right-footer {
                #right-footer-title {}

                .download-link {

                    img {}
                }
            }
        }
    }
}

@media (max-width: 1420px) {
    #footer {
        #footer-content {

            #left-footer {

                #footer-link {
                    a {}

                    #row-link {
                        width: 1000px;

                        ul {

                            li {}
                        }
                    }

                    .nav-menu-footer {

                        .underline-menu {}
                    }
                }

                #rights {
                    width: 1000px;
                }
            }

            #right-footer {
                #right-footer-title {}

                .download-link {

                    img {}
                }
            }
        }
    }
}