@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


body{
  font-family: "Inter", sans-serif;
}

.ant-layout{
  font-family: "Inter", sans-serif;
  background-color: white;
}

.ant-btn{
  font-family: "Inter", sans-serif;
}

a{
  color: #6366F1;
}

.ant-picker {
  border-radius: 4px !important;
  width: 20em;
}

.ant-picker:hover {
  border-color: #6366F1 !important;
}

.ant-picker-focused {
  border-color: #6366F1 !important;
  outline: none !important;
  box-shadow: 0 0 0 2px #8d8ff9 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #6366F1 !important;
  color: #FFFFFF !important;
}

.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected {
  .ant-picker-cell-inner {
      background-color: #6366F1 !important;
  }
}

.ant-picker-today-btn {
  color: #6366F1 !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-picker-range .ant-picker-active-bar {
  background: #6366F1 !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-picker-dropdown .ant-picker-week-panel-row-range-start td.ant-picker-cell:before,
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker-dropdown .ant-picker-week-panel-row-range-end td.ant-picker-cell:before,
:where(.css-dev-only-do-not-override-p7e5j5).ant-picker-dropdown .ant-picker-week-panel-row-selected td.ant-picker-cell:before {
  background: #6366F1 !important;
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-picker-dropdown .ant-picker-week-panel-row-range-hover td:before {
  background: #8d8ff9 !important;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Inter', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* .ant-layout-content{
  display: none !important;
} */

.ant-btn-link{
  color: #6366F1 !important;
}

.ant-table-wrapper .ant-table-filter-trigger.active{
  color: #6366F1 !important;
}
