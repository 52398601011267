.statistic-main {
    margin: 30px;
    background-color: white;
    padding: 2em;
    border-radius: 15px;
	box-shadow: 0 3px 5px #00000005,0 0 2px #0000000d,0 1px 4px #00000014;


    .header-statistic {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em;

        .title {
            margin-bottom: 10px;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #1E1E1E;
        }
    }

    .filters{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1em;
        gap: 1em;
        .filter {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 10px;

        }
    }

}

