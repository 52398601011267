$color_1: rgba(0, 0, 0, 0.88);
$color_2: #4E4E4E;
$font-family_1: "Inter", sans-serif;
$background-color_1: #6366F1;
$border-color_1: #6366F1;

.ant-modal {

	.ant-modal-close {
		display: none;
	}

	.ant-modal-title {
		margin: 0;
		color: $color_1;
		line-height: 1.5;
		word-wrap: break-word;
		text-align: center;
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 700;
		font-size: 30px;
		line-height: 48px;

		img {
			width: 150px;
			object-fit: cover;
		}
	}

	.form-span-text {
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: $color_2;
	}

	.form-input {
		background: #F5F5F5;
		border-radius: 8px;
		height: 35px;

		.ant-input {
			background: #F5F5F5;
		}
	}

	.form-remeber {
		.ant-form-item-control-input-content {
			display: flex;
			justify-content: space-between;
		}
	}

	.form-submit {
		.ant-form-item-control-input-content {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
		}

		.ant-btn.ant-btn-primary.login-form-button {
			width: 100%;
			height: 40px;
			background-color: $background-color_1;
			font-family: $font-family_1;
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 20px;
		}
	}

	.ant-form-item.form-submit {
		margin: 0;
	}

	.div-form {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		.div-form-left {
			display: flex;
			border-bottom: 1px solid #000;
			cursor: pointer;
			transition: all 0.3s ease-in-out;

			&:hover {
				border-bottom: 1px solid $background-color_1;
				color: $background-color_1;
			}
		}
	}
}

.ant-checkbox-checked {
	.ant-checkbox-inner {
		background-color: $background-color_1 !important;
		border-color: $border-color_1 !important;
	}

	&:after {
		border-color: $border-color_1 !important;
	}
}

.ant-checkbox-wrapper {
	&:not(.ant-checkbox-wrapper-disabled) {
		&:hover {
			.ant-checkbox-inner {
				border-color: $border-color_1 !important;
			}
		}
	}
}

.ant-checkbox {
	&:not(.ant-checkbox-disabled) {
		&:hover {
			.ant-checkbox-inner {
				.ant-checkbox-checked {
					.ant-checkbox-inner {
						&:hover {
							border-color: $border-color_1 !important;
						}
					}
				}
			}
		}
	}
}

.ant-input {
	font-family: $font-family_1 !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 20px !important;

	&:hover {
		border-color: $border-color_1 !important;
	}

	&:focus {
		border-color: $border-color_1 !important;
		box-shadow: none !important;
	}

	&:active {
		border-color: $border-color_1 !important;
		box-shadow: none !important;
	}

	&:focus-visible {
		border-color: $border-color_1 !important;
		box-shadow: none !important;
	}

	&:focus:not(:focus-visible) {
		border-color: $border-color_1 !important;
		box-shadow: none !important;
	}

}

.ant-input-password {
	font-family: $font-family_1 !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 20px !important;

	&:hover {
		border-color: $border-color_1 !important;
	}

	&:focus {
		border-color: $border-color_1 !important;
		box-shadow: none !important;
	}

	&:active {
		border-color: $border-color_1 !important;
		box-shadow: none !important;
	}

	&:focus-visible {
		border-color: $border-color_1 !important;
		box-shadow: none !important;
	}

	&:focus:not(:focus-visible) {
		border-color: $border-color_1 !important;
		box-shadow: none !important;
	}
}