.ant-layout-content {
  width: 100% !important;
  margin: 0 !important;
}

.camera-manager-main {
  font-family: "Inter", sans-serif;
  font-style: normal;
  padding: 20px;
  /* background-color: white; */
  border-radius: 15px;
  /* margin: 20px; */
  /* margin-top: 30px; */
  /* box-shadow: 0 3px 5px #00000005,0 0 2px #0000000d,0 1px 4px #00000014 !important; */
}

.camera-manager-main .camera-manager-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.camera-manager-main .camera-manager-title .camera-manager-text {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #1e1e1e;
}

.camera-manager-main .camera-manager-card {
  background-color: #f5f5f5;
}

.camera-manager-main .camera-manager-list .ant-list-item {
  padding: 0;
}

.camera-manager-main .camera-manager-card .ant-card-meta-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #4e4e4e;
}

.camera-manager-main .camera-manager-card .ant-card-meta-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #858585;
}

.camera-fruit {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  // justify-content: space-around;
  // align-items: center;
  // margin: 20px 0;

  &-good,
  &-new,
  &-bad {
    display: flex;
    gap: 10px;
    // flex-direction: column;
    align-items: center;

    &-circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-bottom: 5px;
    }

    &-text {
      font-size: 14px;
      margin-bottom: 5px;
    }

    &-number {
      font-size: 16px;
      font-weight: bold;
    }
  }

  &-good {
    &-circle {
      background-color: green;
    }
  }

  &-new {
    &-circle {
      background-color: yellow;
    }
  }

  &-bad {
    &-circle {
      background-color: red;
    }
  }
}


// body {
//   font-family: Arial, sans-serif;
//   background-color: #f0f4f8;
//   color: #333;
//   line-height: 1.6;
//   padding: 20px;
// }

.container-camera-detail {
  // max-width: 600px;
  width: 99.5%;
  margin-top: -20px;
  margin-left: 3px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  padding-top: 30px;
}

h1 {
  font-size: 24px;
  color: #2c3e50;
  border-bottom: 2px solid #6366F1;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.info {
  margin-bottom: 20px;
}

.info p {
  margin: 5px 0;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table td,
.table th {
  padding: 10px;
  text-align: center;
}

.table th {
  background-color: #6366F1;
  color: white;
  text-transform: uppercase;
  font-weight: normal;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.status {
  font-weight: bold;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  // width: 100px;
  display: inline-block;
}

.status.green {
  background-color: #27ae60;
}

.status.yellow {
  background-color: #f1c40f;
  color: #333;
}

.status.red {
  background-color: #e74c3c;
}