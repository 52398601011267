.pack-manage {
    font-family: "Inter", sans-serif;
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    margin: 20px;
    margin-top: 30px;
    box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;

    .main-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .title {
            font-weight: 700;
            font-size: 24px;
        }
    }

    .action-cell-table {
        display: flex;
        justify-content: center;
        gap: 10px;

        span {
            cursor: pointer;
            transition: all 0.3s ease;

            :hover{
                color: #6366F1;
            }
        }
        // cursor: pointer;
    }
}

.main-title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
}

.action {
    margin: 10px;
    cursor: pointer;
}

.type {
    color: #6366F1;
    font-weight: 600;
}

.ant-btn {
    font-family: "Inter", sans-serif !important;
}

.ant-table-wrapper {
    font-family: "Inter", sans-serif !important;

    .ant-table {
        font-family: "Inter", sans-serif !important;

    }

    .ant-pagination {
        font-family: "Inter", sans-serif !important;
    }

    .ant-table-cell {
        text-align: center !important;
    }
}

:where(.css-dev-only-do-not-override-p7e5j5).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #FFFFFF;

}

.ant-select {

    .ant-select-selector {
        color: #696969;

        .ant-select-selection-search {
            .ant-select-selection-search-input {
                // margin-left: 10px;
            }
        }
    }

    .ant-select-selector:hover {
        outline: none !important;
        border: 1px solid #6366F1 !important;
    }

    .ant-select-selector:focus {
        outline: none !important;
        border: 1px solid #6366F1 !important;
        z-index: 3;
        box-shadow: 0 0 0 1px #6366F1 !important;
        caret-color: #6366F1 !important;

    }

    .ant-select-arrow {
        color: #4E4E4E !important;
        z-index: 3;
    }

    .ant-select-clear {
        color: #4E4E4E !important;
        z-index: 3;
        right: 35px;
        top: 45%;
        width: 7px;
        height: 7px;


    }

    .ant-select-clear:hover {
        opacity: .5;
    }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #6366F1 !important;
    box-shadow: 0 0 0 2px #1bce000c !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    outline: none !important;
    border: 1px solid #6366F1 !important;
    box-shadow: 0 0 0 1px #6366F1 !important;
    caret-color: #6366F1 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #6366F1 !important;
    color: #FFFFFF;
}

.ant-select-item {
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    color: #696969;
}

.ant-select-item:hover {
    background-color: #6366F1 !important;
    color: #FFFFFF;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
    border-radius: 15px;
    padding: 0;
    background: #EEF2F6;

}

.ant-select-item-option-disabled,
.ant-select-item-option-disabled:hover {
    background: #DFDFDF;
    color: #C0C0C0;
}

.packs-detail {
    .table-pack-manage {}
}

.main-detail-pack-manager {
    // margin: 20px;
    // margin-top: 50px;
    font-family: "Inter", sans-serif;
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    margin: 20px;
    margin-top: 30px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.0196078431), 0 0 2px rgba(0, 0, 0, 0.0509803922), 0 1px 4px rgba(0, 0, 0, 0.0784313725) !important;

    .title {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 20px;
    }

    .table-detail-pack-manager {
        .action-lst {
            display: flex;
            justify-content: center;
            gap: 10px;
        }
    }
}

.modal-edit-agriculture-type {
    .ant-modal-title {
        font-weight: 700;
        font-size: 24px;
    }

    .ant-form-item-label>label {
        width: 10em;
    }
}


$color_1: #FFFFFF;
$color_2: white;
$color_3: #6366F1;
$font-family_1: "Inter", sans-serif;
$background-color_1: #6366F1;

.body {
    font-family: $font-family_1;
    font-style: normal;
}

.modal-add-pack {
    .ant-modal-content {
        padding: 0;
    }

    .ant-modal-header {
        padding: 16px 32px;
        background: #6366F1;
    }

    .ant-modal-title {
        text-align: left;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $color_1;
    }

    .ant-modal-close {
        display: block;
        color: $color_2;

        &:hover {
            color: $color_2;
        }
    }

    .ant-modal-close-x {
        font-size: 20px;
    }

    .ant-modal-body {
        padding: 24px;
    }

    .ant-modal-footer {
        text-align: center;
        padding-bottom: 32px;
        margin-top: -10px;
    }

    .ant-btn.ant-btn-default {
        height: 32px;
        padding: 4.5px 19px;
        align-items: flex-start;
        gap: 8px;
        border-radius: 10px;
        border: 1.5px solid #6366F1;
        color: $color_3;
    }

    .btn-cancel {
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        color: $color_3;
    }

    .btn-submit {
        background-color: $background-color_1;
        font-family: $font-family_1;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: $color_1;

        &:hover {
            background-color: $background-color_1;
        }
    }

    .ant-picker {
        width: 100%;
    }

    .ant-modal-footer {
        display: none;
    }

    .ant-btn {
        border-radius: 8px !important;
    }


    .ant-col.ant-form-item-label {
        height: 30px;
    }

    .ant-form-item-label>label {
        display: inline;
    }

    .custom-label {
        display: flex;
        gap: 4px;
        align-items: center;
    }

    .required-asterisk {
        color: #ff4d4f;
    }


}

.ant-form-item .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}