.main-active-account {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: white;
}

.ActiveAccountModel {
    top: 200px;

    .ant-modal-content {

        .ant-modal-close {
            display: none;
        }

        .ant-modal-header {
            display: none;
        }

        .ant-modal-footer {
            display: none;
        }

        .ant-modal-body {
            .title {
                font-weight: 700;
                font-size: 30px;
                line-height: 56px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: linear-gradient(308.85deg, #FF6600 0%, #FF9955 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }

            .content,
            .email-account {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: #858585;

                strong {
                    margin-left: 10px;
                }
            }

            .email-account {
                margin: 10px 0;
            }

            .button-confirm {
                display: flex;
                align-items: center;
                justify-content: center;

                .ant-btn {
                    width: 50%;
                    background: linear-gradient(308.85deg, #FF6600 0%, #FF9955 100%);
                    border-radius: 30px;
                    border: none;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    color: #FFFFFF;
                }
            }
        }
    }
}

// body {
//     font-family: Arial, sans-serif;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100vh;
//     margin: 0;
//     background-color: #f0f0f0;
// }

.container-active {
    text-align: center;
    margin: 2em;
    padding: 2rem;
    width: 700px;
    // height: 100vh;
    // background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    h1 {
        color: #333;
    }

    p {
        color: #666;
    }

    .loader {
        border: 4px solid #f3f3f3;
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
        margin: 20px auto;
    }
}



@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}