.overview {
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0px 32px;

    .left-contents {
        flex: 1;


        .left-top-content {
            display: grid;

            // Hiển thị 2 cột 2 dòng
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 20px;
            margin-bottom: 20px;

            .fruit-type {
                display: flex;
                gap: 15px;
                flex-direction: column;
                justify-content: start;
                align-items: start;
                border-radius: 10px;
                padding: 20px;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                background-color: white;
                box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;

                .left-top-content-title {
                    font-size: 14px;
                    font-weight: 500;
                    color: #9E9E9E;
                }

                .left-top-content-value {
                    font-size: 1.25em;
                    font-weight: 500;
                    color: #000000;

                }

                .left-top-content-description {
                    font-size: 14px;
                    font-weight: 500;
                    color: #9E9E9E;
                }
            }
        }

    }

    .right-contents {
        // background-color: white;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 20px;
        //center 
        align-items: center;

        .right-chart {
            background-color: white;
            border-radius: 10px;
            padding: 20px;
            box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
            width: 100% !important;
        }

        .right-table {
            width: 100%;
            background-color: white;
            border-radius: 10px;
            box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
        }

        .recharts-legend-wrapper {
            left: 13% !important;
            bottom: 0 !important;
        }
    }

    .container-overview {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex: 1;

        .container-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .container-title-text {
                font-size: 1.75em;
                font-weight: 600;
                color: #000000;
            }
        }

        .containers {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;

            .info-container {
                position: relative;
                display: flex;
                gap: 20px;
                flex-direction: column;
                background-color: white;
                border-radius: 10px;
                padding: 20px;
                box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
                cursor: pointer;
                // height: 100%;
                .info-icon {
                    position: absolute;
                    top: 15px;
                    right: 20px;
                    font-size: 2em;
                    color: #9E9E9E;
                    cursor: pointer;
                }

                .info-title {
                    font-size: 1.5em;
                    font-weight: 600;
                    color: #a5a4ad;
                    margin-right: 20px;
                }

                .info-item {
                    display: flex;
                    flex-direction: column;
                    gap: .5em;

                    .info-item-title {
                        font-size: 14px;
                        font-weight: 500;
                        color: #9E9E9E;
                    }

                    .info-item-value {
                        font-size: 1.25em;
                        font-weight: 500;
                        color: #000000;

                    }

                    .info-item-description {
                        font-size: 14px;
                        font-weight: 500;
                        color: #9E9E9E;
                    }

                    .info-item-content {
                        font-size: 1.5em;
                        font-weight: 700;
                        color: #000000;
                    }

                    .info-item-note {
                        font-size: 1em;
                        font-weight: 500;
                        color: #9E9E9E;

                        span {
                            color: #6366F1;
                        }
                    }
                }
            }
        }



    }

    .container-overview.warehouse-overview {
        .info-container {
            display: flex;
            gap: 20px;
            flex: 1;
            flex-direction: column;
            background-color: white;
            border-radius: 10px;
            padding: 20px;
            box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;


            .info-title {
                text-align: center;
                font-size: 1.5em;
                font-weight: 600;
            }

            .info-item {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1em;
            }
        }

    }

}

// @media (min-width: 2000px) {
//     .overview {
//         font-family: 'Inter', sans-serif;
//         display: flex;
//         flex-direction: column;
//         gap: 20px;
//         padding: 0px 32px;

//         .left-contents {
//             flex: 1;
//             .left-top-content {
//                 display: grid;

//                 // Hiển thị 2 cột 2 dòng
//                 grid-template-columns: 1fr 1fr;
//                 grid-template-rows: 1fr 1fr;
//                 gap: 20px;
//                 margin-bottom: 20px;

//                 .fruit-type {
//                     display: flex;
//                     gap: 15px;
//                     flex-direction: column;
//                     justify-content: start;
//                     align-items: start;
//                     border-radius: 10px;
//                     padding: 20px;
//                     cursor: pointer;
//                     transition: all 0.2s ease-in-out;
//                     background-color: white;
//                     box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;

//                     .left-top-content-title {
//                         font-size: 14px;
//                         font-weight: 500;
//                         color: #9E9E9E;
//                     }

//                     .left-top-content-value {
//                         font-size: 1.25em;
//                         font-weight: 500;
//                         color: #000000;

//                     }

//                     .left-top-content-description {
//                         font-size: 14px;
//                         font-weight: 500;
//                         color: #9E9E9E;
//                     }
//                 }
//             }

//         }

//         .right-contents {
//             // background-color: white;
//             flex: 1;
//             display: flex;
//             flex-direction: column;
//             gap: 20px;
//             //center 
//             align-items: center;

//             .right-chart {
//                 background-color: white;
//                 border-radius: 10px;
//                 padding: 20px;
//                 box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
//                 width: 100% !important;
//             }

//             .right-table {
//                 width: 100%;
//                 background-color: white;
//                 border-radius: 10px;
//                 box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
//             }

//             .recharts-legend-wrapper {
//                 left: 13% !important;
//                 bottom: 0 !important;
//             }
//         }

//         .container-overview {
//             display: flex;
//             flex-direction: column;
//             gap: 20px;
//             flex: 1;

//             .container-title {
//                 display: flex;
//                 flex-direction: column;
//                 justify-content: center;
//                 align-items: center;
//                 gap: 10px;

//                 .container-title-text {
//                     font-size: 1.75em;
//                     font-weight: 600;
//                     color: #000000;
//                 }
//             }

//             .containers {
//                 display: grid;
//                 grid-template-columns: 1fr 1fr 1fr;
//                 gap: 20px;

//                 .info-container {
//                     position: relative;
//                     display: flex;
//                     gap: 20px;
//                     flex-direction: column;
//                     background-color: white;
//                     border-radius: 10px;
//                     padding: 20px;
//                     box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;

//                     height: 10vw;
//                     .info-icon {
//                         position: absolute;
//                         top: 15px;
//                         right: 20px;
//                         font-size: 2em;
//                         color: #9E9E9E;
//                         cursor: pointer;
//                     }

//                     .info-title {
//                         font-size: 1.5em;
//                         font-weight: 600;
//                         color: #a5a4ad;
//                         margin-right: 20px;
//                     }

//                     .info-item {
//                         display: flex;
//                         flex-direction: column;
//                         gap: .5em;

//                         .info-item-title {
//                             font-size: 14px;
//                             font-weight: 500;
//                             color: #9E9E9E;
//                         }

//                         .info-item-value {
//                             font-size: 1.25em;
//                             font-weight: 500;
//                             color: #000000;

//                         }

//                         .info-item-description {
//                             font-size: 14px;
//                             font-weight: 500;
//                             color: #9E9E9E;
//                         }

//                         .info-item-content {
//                             font-size: 1.5em;
//                             font-weight: 700;
//                             color: #000000;
//                         }

//                         .info-item-note {
//                             font-size: 1em;
//                             font-weight: 500;
//                             color: #9E9E9E;

//                             span {
//                                 color: #6366F1;
//                             }
//                         }
//                     }
//                 }
//             }



//         }

//         .container-overview.warehouse-overview {
//             .info-container {
//                 display: flex;
//                 gap: 20px;
//                 flex: 1;
//                 flex-direction: column;
//                 background-color: white;
//                 border-radius: 10px;
//                 padding: 20px;
//                 box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;


//                 .info-title {
//                     text-align: center;
//                     font-size: 1.5em;
//                     font-weight: 600;
//                 }

//                 .info-item {
//                     display: flex;
//                     justify-content: center;
//                     align-items: center;
//                     gap: 1em;
//                 }
//             }

//         }

//     }

// }