.ant-layout-content {
  width: 100% !important;
  margin: 0 !important;
}
.camera-manager-main {
  font-family: "Inter", sans-serif;
  font-style: normal;
  padding: 20px;
}

.camera-manager-main .camera-manager-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1e1e;
}

.camera-manager-main .camera-manager-card {
  background-color: white;
  box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
}

.camera-manager-main .camera-manager-list .ant-list-item {
  padding: 0;
}

.camera-manager-main .camera-manager-card .ant-card-meta-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #4e4e4e;
}

.camera-manager-main .camera-manager-card .ant-card-meta-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #858585;
}

.container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-wrap: wrap;
  background-color: white;
  padding: 20px;
  box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;
  border-radius: 15px;
  margin-top: 2em;
}

.container .title-container {
  font-weight: 500;
  font-size: 20px;
}

.container .lst-item {
  /* Hiển thị grid với 3 item 1 row */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;
  column-gap: 50px;
}

.container .lst-item .ant-card {
  width: 100%;
  height: 100%;
}

.container .lst-item .ant-card img {
  width: 100%;
  height: 15vw;
  /* height: 100%; */
  object-fit: cover;
}

.bounding-boxes {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}

.bounding-box {
  position: absolute;
  /* top: 20%; */
  /* border: 3px solid burlywood; */
  box-sizing: border-box;
}

.mango-info {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  border-radius: 3px;
}

.contailer-video{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
}

.ant-tooltip-inner{
  
  width: fit-content;

  .title-tooltip{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
  }
}

.title-tooltip div{
  margin: 5px;
}

.title-tooltip div:first-child{
  font-weight: 700;
  font-size: 16px;
}