	.container-dashboard {
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 1em 2em;
		width: 100%;

		.overview-body {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: repeat(1, 1fr);
			grid-column-gap: 15px;
			grid-row-gap: 15px;
			// grid-template-rows: 200px 200px 200px 200px 200px;
			grid-column-gap: 40px;
			grid-row-gap: 40px;

			.overview-body-item {
				background: linear-gradient(138.87deg, #FFFFFF 0%, #FFFFFF 99.02%);
				border-radius: 15px;
				// display: flex;
				justify-content: space-between;
				cursor: pointer;
				height: max-content;
				box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;

			}

		}

		.overview-body-text {
			display: flex;
			flex-direction: column;
			margin: 5% 0px 5% 5%;

			.overview-body-text-small {
				font-weight: 700;
				font-size: 14px;
				line-height: 17px;
				color: #5e5858;
			}

			.overview-body-text-large {
				font-weight: 700;
				font-size: 30px;
				line-height: 40px;
				color: #5e5858;
				text-align: end;
				margin-right: 20%;
			}
		}

		.overview-chart {
			// margin-top: 30px;
			border-radius: 15px;
			// padding: 50px 5% 50px 5%;
			background-color: white;
			display: flex;
			justify-content: space-between;
			// align-items: center;
			padding: 20px;
			box-shadow: 0 3px 5px #00000005, 0 0 2px #0000000d, 0 1px 4px #00000014 !important;


			// .bar-chart {
			// 	width: 60%;
			// 	border-radius: 5px;

			// }

			// .pie-chart {
			// 	width: 40%;
			// 	background-color: #FFFFFF;
			// 	border-radius: 5px;
			// }
		}

	}



	.ant-pagination-options {
		display: none;
	}

	.pie-chart {
		width: 50%;
	}
	.label-chart {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 10px;
		margin: 10px;
		font-size: 18px;
		font-weight: 700;
		color: #575353;
	}

	#SvgjsText1319 {
		font-family: "Inter", sans-serif !important;
	}

	#SvgjsText2707 {
		font-family: "Inter", sans-serif !important;
	}

	.bar-chart{
		font-family: "Inter", sans-serif !important;
	}