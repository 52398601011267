.warehouse-main {
    margin: 30px;
    padding: 50px;
    background-color: white;
    border-radius: 15px;

    .warehouse-title {
        display: flex;
        justify-content: space-between;

        .title-text {
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #1E1E1E;
        }

        .title-button {
            width: 200px;
            background: #6366F1;
            border-radius: 10px;
            font-family: "Inter", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;
        }

        .title-button:hover {
            border-color: #6366F1;
            background-color: #6366F1;
        }
    }

    .warehouse-table {
        .default-button {
            height: 32px;
            border-radius: 10px;
            border: 1.5px solid #6366F1;

            .default-button-icon {
                width: 20px;
                height: 20px;
                align-self: center;

                .icon-default {
                    margin-bottom: 10px;
                }
            }

            .default-button-text {
                font-family: "Inter", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #6366F1;
            }

        }

        .MuiTableHead-root {
            background-color: #7779f1;

            .MuiTableCell-root {
                font-family: "Inter", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
            }
        }

        .MuiTableBody-root {
            .MuiTableRow-root {
                height: 55px;

                .MuiTableCell-root {
                    border: none;
                }

                .MuiTableBody-root:last-child {
                    width: 100px;
                }
            }

            .MuiTableRow-root:hover {
                background-color: #7779f1;
            }
        }

    }
}